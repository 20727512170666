import selfLabel from "@/components/crowdsourcing/selfLabelDataField";
import _ from "lodash";

export default function (spaceField, propertyField) {
  const propertyName = _.get(propertyField, "fieldContent.name");
  let propertyLabel = "";

  if (propertyName) {
    propertyLabel = `${propertyName}: `;
  } else if (propertyField?.joiningContentType === "Property") {
    propertyLabel = `${propertyField.joiningContentType} ${propertyField.joiningContentId}: `;
  }

  const name = spaceField?.fieldContent?.name;
  const spaceType =
    spaceField?.fieldContent?.type ||
    spaceField?.fieldContentSubType ||
    spaceField?.fieldContentType;
  const spaceFieldLabel = spaceField
    ? selfLabel(spaceField)
    : "Unknown space type";
  const unnamed = _.lowerCase(name) === _.lowerCase(spaceType);
  let label = null;

  if (!unnamed) {
    label = `${name} (${spaceFieldLabel})`;
  } else if (spaceField) {
    label = `${spaceFieldLabel}`;
  } else {
    label = `${_.startCase(spaceType)} ${spaceField?.fieldContentId}`;
  }

  return `${propertyLabel}${label}`;
}
