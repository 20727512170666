export default [
  {
    order: 1,
    name: "Available",
    statusLabel: "Available",
    value: "live",
  },
  {
    order: 2,
    name: "Under contract",
    statusLabel: "Under contract",
    value: "under_contract",
  },
  {
    order: 3,
    name: "Closed",
    statusLabel: "Closed",
    value: "closed",
  },
  {
    order: 4,
    name: "Withdrawn",
    statusLabel: "Withdrawn",
    value: "withdrawn",
  },
];
