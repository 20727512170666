/* global L */
import _ from "lodash";
import moment from "moment";
import pluralize from "pluralize";
import milestones from "@/assets/contentMilestones";
import investmentStates from "@/components/deal-builder/investmentStates";
import { currencyAmount } from "@/assets/numberHelpers";
import stateAbbreviationsUS from "@/assets/stateAbbreviationsUS";

export default function selfLabel(
  dataField,
  propertyDataField = null,
  outputFormat = null,
) {
  const fieldName =
    _.get(dataField, "fieldName") || _.get(dataField, "fieldContentType");
  const fieldValue = _.get(dataField, "fieldValue");

  switch (fieldName) {
    case "name":
    case "internal_name":
    case "ticker_symbol":
    case "contact_info":
    case "street_address":
    case "horizontal_alignment":
    case "url":
    case "description":
    case "note_text":
      return fieldValue;
    case "paywalled": {
      if (fieldValue === "true") {
        return "Is paywalled";
      } else if (fieldValue === "false") {
        return "Not paywalled";
      } else {
        return "Paywall status: unknown";
      }
    }
    case "hunts_equity":
      if (fieldValue === "true") {
        return "Acquires Equity Interests";
      } else if (fieldValue === "false") {
        return "Not interested in Equity";
      } else {
        return null;
      }
    case "hunts_debt":
      if (fieldValue === "true") {
        return "Provides Financing";
      } else if (fieldValue === "false") {
        return "Not interested in Financing";
      } else {
        return null;
      }
    case "seniority_type":
    case "equity_type":
      return _.startCase(fieldValue);
    case "min_area_per_person":
    case "max_area_per_person":
    case "min_area":
    case "max_area":
    case "standardized_area":
    case "minimum_subdivision_standardized_area":
      if (fieldValue) {
        return `${currencyAmount(fieldValue, 0)} SF`;
      } else {
        return null;
      }
    case "use_based_area":
      if (fieldValue) {
        return `${currencyAmount(fieldValue, 0)} units`;
      } else {
        return null;
      }
    case "height":
      if (fieldValue) {
        return `${currencyAmount(fieldValue, 0)} feet`;
      } else {
        return null;
      }
    case "land_area": {
      if (fieldValue) {
        return `${currencyAmount(fieldValue, 1)} acres`;
      } else {
        return null;
      }
    }
    case "fully_funded_loan_amount":
    case "min_investment_size":
    case "max_investment_size":
    case "value": {
      if (fieldValue) {
        return `$${currencyAmount(fieldValue, 0)}`;
      } else {
        return null;
      }
    }
    case "loan_term_years": {
      if (fieldValue) {
        return `${fieldValue} Yrs`;
      } else {
        return null;
      }
    }
    case "loan_coupon_rate": {
      if (fieldValue) {
        return `${currencyAmount(fieldValue, 2)}%`;
      } else {
        return null;
      }
    }
    case "density_percent_modifier": {
      if (fieldValue) {
        return `${currencyAmount(fieldValue, 0)}%`;
      } else {
        return null;
      }
    }
    case "min_headcount":
    case "max_headcount": {
      if (fieldValue) {
        return `${currencyAmount(fieldValue, 0)}`;
      } else {
        return null;
      }
    }
    case "contract_state": {
      let currentStateObject;
      let matchingOptions = null;
      matchingOptions = _.get(milestones, dataField.decoratingContentType, []);

      const dropdownOptions = _.sortBy(matchingOptions, ["order"]);
      currentStateObject = _.find(dropdownOptions, {
        value: fieldValue,
      });

      if (currentStateObject) {
        return currentStateObject.name;
      } else {
        return null;
      }
    }
    case "occupancy_state": {
      let currentStateObject;
      let matchingOptions = null;
      matchingOptions = _.get(milestones, "SpaceUsageOccupancy", []);

      const dropdownOptions = _.sortBy(matchingOptions, ["order"]);
      currentStateObject = _.find(dropdownOptions, {
        value: fieldValue,
      });

      if (currentStateObject) {
        return currentStateObject.name;
      } else {
        return null;
      }
    }
    case "state": {
      let currentStateObject;

      if (dataField.decoratingContentType === "Investment") {
        currentStateObject = _.find(investmentStates, {
          value: fieldValue,
        });
      } else {
        let matchingOptions = null;
        if (dataField.decoratingContentType === "PropertyRight") {
          matchingOptions = _.get(
            milestones,
            `PropertyRight[${dataField.decoratingContentSubType}]`,
            [],
          );
        } else {
          matchingOptions = _.get(
            milestones,
            dataField.decoratingContentType,
            [],
          );
        }

        const dropdownOptions = _.sortBy(matchingOptions, ["order"]);
        currentStateObject = _.find(dropdownOptions, {
          value: fieldValue,
        });
      }

      if (currentStateObject) {
        return currentStateObject.name;
      } else {
        return null;
      }
    }
    case "live_date":
    case "under_contract_date":
    case "closed_date":
    case "withdrawn_date":
    case "executed_date":
    case "commenced_date":
    case "engaged_date":
    case "terminated_date":
    case "expired_date":
    case "created_date":
    case "destroyed_date":
    case "granted_date":
    case "used_date":
    case "fulfilled_date":
    case "ceased_date":
    case "active_date":
    case "hypothetical_date":
    case "construction_proposed_date":
    case "demolition_proposed_date":
    case "construction_approved_date":
    case "construction_permitted_date":
    case "construction_commenced_date":
    case "demolition_permitted_date":
    case "construction_completed_date":
    case "demolished_date":
    case "origination_date":
    case "maturity_date":
    case "awarded_at":
    case "dropped_at":
    case "opened_at":
    case "retired_at":
    case "retired_date":
    case "move_in_date":
    case "move_out_date":
    case "target_occupancy_date":
    case "target_date":
    case "date": {
      if (fieldValue) {
        return moment(fieldValue).format("MMM D, YYYY");
      } else {
        return null;
      }
    }
    case "Investment": {
      let propertyName = "";

      if (propertyDataField) {
        propertyName = `${_.get(propertyDataField, "fieldContent.name")} - `;
      } else if (dataField.fieldContent.topLevelPropertyName) {
        propertyName = `${dataField.fieldContent.topLevelPropertyName} - `;
      }
      const assetAlias = () => {
        return dataField.fieldContent.asset?.fieldContent?.name;
      };

      const assetTypeAlias = () => {
        switch (dataField.fieldContent.investmentType) {
          case "OwnershipInterest":
            return "Equity";
          case "Loan":
            return "Loan";
          default:
            return "Unknown Asset";
        }
      };
      const detailedInvestmentLabel = () => {
        const loanSeniority = _.get(dataField.fieldContent, "loanSeniority");

        if (loanSeniority) {
          return `${_.startCase(loanSeniority)} ${assetTypeAlias()}`;
        } else {
          return assetTypeAlias();
        }
      };
      const transactionType = () => dataField.fieldContent.dealActionAlias;

      if (assetAlias() && _.trim(propertyName) !== "") {
        const dateLabel = () => {
          switch (dataField.fieldContent.state) {
            case "withdrawn":
            case "live":
            case "under_contract":
              return _.find(investmentStates, {
                value: dataField.fieldContent.state,
              }).statusLabel;
            case "closed":
            default: {
              if (dataField.fieldContent.date) {
                return moment(dataField.fieldContent.date).format("MMM YYYY");
              } else {
                return "Undated";
              }
            }
          }
        };
        return `${propertyName}${assetAlias()} ${detailedInvestmentLabel()} ${transactionType()}, ${dateLabel()}`;
      } else {
        return `${detailedInvestmentLabel()} ${transactionType()}`;
      }
    }
    case "SpaceAvailability": {
      let propertyName = "";

      const spaceType = () => {
        return dataField.fieldContent.space?.fieldContent?.recordType;
      };
      const renewal = () => {
        return dataField.fieldContent.renewal;
      };
      const typeLabel = () => {
        if (spaceType() === "SpaceUsage") {
          return " (Sublease)";
        } else if (renewal()) {
          return " (Renewal)";
        } else {
          return "";
        }
      };

      if (propertyDataField) {
        propertyName = `${_.get(
          propertyDataField,
          "fieldContent.name",
        )}${typeLabel()} - `;
      } else if (dataField.fieldContent.topLevelPropertyName) {
        propertyName = `${
          dataField.fieldContent.topLevelPropertyName
        }${typeLabel()} - `;
      }
      const spaceAlias = () => {
        return (
          dataField.fieldContent.topLevelSpace?.fieldContent?.name ||
          dataField.fieldContent.topLevelSpace?.name
        );
      };

      const spaceFloorLevels = () => {
        return (
          dataField.fieldContent.topLevelSpace?.fieldContent?.verticalLevels ||
          dataField.fieldContent.topLevelSpace?.verticalLevels
        );
      };
      const floorLevelsLabel = () => {
        const levels = spaceFloorLevels();
        if (levels) {
          const pluralizedFloors = pluralize("Floor", levels.length);
          return ` (${pluralizedFloors} ${levels.join(", ")})`;
        } else {
          return "";
        }
      };

      if (spaceAlias() && _.trim(propertyName) !== "") {
        const dateLabel = () => {
          switch (dataField.fieldContent.state) {
            case "withdrawn":
            case "live":
            case "under_contract":
              return _.find(investmentStates, {
                value: dataField.fieldContent.state,
              }).statusLabel;
            case "closed":
            default: {
              if (dataField.fieldContent.date) {
                return moment(dataField.fieldContent.date).format("MMM YYYY");
              } else {
                return "Undated";
              }
            }
          }
        };
        return `${propertyName}${spaceAlias()}, ${dateLabel()}${floorLevelsLabel()}`;
      } else if (
        _.includes(["PropertyEnhancement", "LayoutPartition"], spaceType())
      ) {
        return `${spaceAlias()} license`;
      } else {
        return `Lease${floorLevelsLabel()}`;
      }
    }
    case "SpaceUsage": {
      let propertyName = "";
      let locationLabel = "";

      const state = () => {
        return dataField.fieldContent.state;
      };
      const unlicensed = () => {
        return _.isObject(state());
      };

      if (unlicensed()) {
        return _.startCase(fieldName);
      } else {
        const space = () => {
          return dataField.fieldContent.space;
        };
        const spaceType = () => {
          return dataField.fieldContent.spaceType;
        };
        const renewal = () => {
          return dataField.fieldContent.renewal;
        };
        const typeLabel = () => {
          if (
            spaceType() === "SpaceUsage" ||
            dataField.fieldContentSubType === "subordinate_space"
          ) {
            return " (Sublease)";
          } else if (renewal()) {
            return " (Renewal)";
          } else {
            return "";
          }
        };
        const spaceName = (prefix = "", suffix = "") => {
          const name = space()?.name || dataField.fieldContent?.spaceType;
          if (
            !!name &&
            !_.includes(
              ["Floor Area", "Enhancement", "Floor Layout Room/Partition"],
              name,
            )
          ) {
            return `${prefix}${name}${suffix}`;
          } else {
            return "";
          }
        };

        const uses = () => {
          return space()?.useTypes;
        };
        const usesLabel = () => {
          const u = uses();
          if (u) {
            return `${u.join(", ")}, `;
          } else {
            return "";
          }
        };

        const roomType = () => {
          if (space()?.recordType === "LayoutPartition") {
            return `${space()?.type}`;
          } else {
            return "";
          }
        };

        const enhancementType = () => {
          if (space()?.recordType === "PropertyEnhancement") {
            return `${_.startCase(space()?.recordType)}: `;
          } else {
            return "";
          }
        };

        const spaceFloorLevels = () => {
          return space()?.verticalLevels;
        };
        const floorLevelsLabel = (suffix = "") => {
          const levels = spaceFloorLevels();
          if (levels) {
            const pluralizedFloors = pluralize("Floor", levels.length);
            return `${pluralizedFloors} ${levels.join(", ")}${suffix}`;
          } else {
            return "";
          }
        };

        if (dataField.fieldContent.property?.name) {
          if (
            dataField.fieldContent.property?.locationDataField?.fieldContent
              ?.city
          ) {
            const state =
              dataField.fieldContent.property?.locationDataField?.fieldContent
                ?.state;
            const abbreviatedState = stateAbbreviationsUS[state];

            locationLabel = `${
              dataField.fieldContent.property?.locationDataField?.fieldContent
                ?.city
            }, ${abbreviatedState || state}`;
            propertyName = `${dataField.fieldContent.property.name} (${locationLabel}) - `;
          } else {
            propertyName = `${dataField.fieldContent.property.name} - `;
          }
        }

        let spaceLabel = "";

        if (dataField.fieldContent.space?.standardizedArea) {
          spaceLabel = `${currencyAmount(
            dataField.fieldContent.space?.standardizedArea,
            0,
          )} SF`;
        }

        switch (outputFormat) {
          case "rentRollName": {
            const namePrefix =
              space()?.recordType === "PropertyEnhancement" ? "" : ", ";
            return `${usesLabel()}${roomType()}${enhancementType()}${floorLevelsLabel()}${spaceName(
              namePrefix,
            )}${typeLabel()}`;
          }
          default:
            return `${propertyName}${floorLevelsLabel(
              ": ",
            )}${spaceLabel}${typeLabel()}`;
        }
      }
    }
    case "Loan":
      return `${_.startCase(
        dataField.fieldContent.state || dataField.fieldContent.category,
      )} ${dataField.fieldContent.seniority} loan`;
    case "Property": {
      const name = dataField.fieldContent.name;
      const isAirLayer = dataField.fieldContent.groundLayerPropertyId;
      const isGroundLayer = dataField.fieldContent.airLayerPropertyId;

      if (_.isObject(name)) {
        return `Property${isAirLayer ? " (Air Rights)" : ""}${
          isGroundLayer ? " (Ground Layer)" : ""
        }`;
      } else {
        return `${name}${isAirLayer ? " (Air Rights)" : ""}${
          isGroundLayer ? " (Ground Layer)" : ""
        }`;
      }
    }
    case "LayoutPartition":
      switch (outputFormat) {
        case "partitionGroup": {
          if (
            !_.isObject(dataField.fieldContent.name) &&
            dataField.fieldContent.name !== "Floor Layout Room/Partition"
          ) {
            return `${dataField.fieldContent.name} (${dataField.fieldContent.type})`;
          } else {
            return dataField.fieldContent.type;
          }
        }
        default:
          return dataField.fieldContent.type || dataField.fieldContent.name;
      }
    case "InvestmentOffer":
    case "Valuation":
      return dataField.fieldContent.recordType;
    case "InvolvementRole":
    case "Company":
    case "ContentPropertyUse":
    case "ContentAlias":
    case "CashflowSchedule":
    case "PropertyEnhancement": {
      if (_.isObject(dataField.fieldContent.name)) {
        return _.startCase(fieldName);
      } else {
        return dataField.fieldContent.name;
      }
    }
    case "FloorAreaLayout": {
      if (_.isObject(dataField.fieldContent.name)) {
        return _.startCase(fieldName);
      } else {
        const layoutState = dataField.fieldContent.state;
        const stateLabel = layoutState ? ` (${_.startCase(layoutState)})` : "";
        return `${dataField.fieldContent.name}${stateLabel}`;
      }
    }
    case "Hunt": {
      let huntName = null;

      if (_.isObject(dataField.fieldContent.name)) {
        huntName = _.startCase(fieldName);
      } else {
        huntName = dataField.fieldContent.name;
      }

      if (
        _.isNil(dataField.decoratingContentType) ||
        _.isObject(dataField.fieldContent.companyName) ||
        _.isNil(dataField.fieldContent.companyName)
      ) {
        return huntName;
      } else {
        return `${dataField.fieldContent.companyName}: ${huntName}`;
      }
    }
    case "HuntGeographyIntent": {
      if (_.isObject(dataField.fieldContent.name)) {
        return _.startCase(fieldName);
      } else {
        return dataField.fieldContent.name;
      }
    }
    case "CompanyInvolvement":
    case "ContactCompanyInvolvement": {
      if (dataField.fieldContent?.huntClient?.name) {
        return `Hunting for ${dataField.fieldContent.huntClient.name}`;
      } else {
        return dataField.fieldContent.name;
      }
    }
    case "InvestmentGroup": {
      const name = dataField.fieldContent.name;
      const nameLabel = name === "InvestmentGroup" ? "Portfolio" : name;
      const dealAction = dataField.fieldContent?.dealActionAlias;
      const loanSeniority = _.includes(
        ["Origination", "Refinancing"],
        dealAction,
      )
        ? ` ${_.startCase(dataField.fieldContent?.loanSeniority)} `
        : "";
      const loanState =
        _.includes(["Origination", "Refinancing"], dealAction) &&
        dataField.fieldContent?.loanState
          ? ` (${_.startCase(dataField.fieldContent?.loanState)})`
          : "";
      const dealActionLabel = dealAction
        ? ` - ${loanSeniority}${dealAction}${loanState}`
        : "";

      return `${nameLabel}${dealActionLabel}`;
    }
    case "SpaceAvailabilityGroup": {
      const name = dataField.fieldContent.name;
      let nameLabel = "Group";
      if (!_.isObject(name)) {
        nameLabel = name === "SpaceAvailabilityGroup" ? "Group" : name;
      }

      return `${nameLabel}`;
    }
    case "OwnershipInterest": {
      const equityType = dataField.fieldContent.equityType;
      const assetType = dataField.fieldContent.assetType;

      return `${_.startCase(assetType)} (${equityType} equity)`;
    }
    case "PropertyRight":
    case "LandCovering": {
      const name = dataField.fieldContent.name;
      const type = dataField.fieldContent.type;

      if (name && type && name === type) {
        return name;
      } else if (outputFormat === "compact") {
        return name;
      } else {
        return `${name} (${type})`;
      }
    }
    case "LandCoveringGrouping": {
      const name = dataField.fieldContent?.name;

      if (_.isObject(name)) {
        return "Combined Building";
      } else {
        return name;
      }
    }
    case "LandCoveringDiagramVerticalGrouping": {
      const name = dataField.fieldContent?.name;

      if (_.isObject(name) || name === "Vertical Grouping") {
        return "Same-width floors";
      } else {
        return name;
      }
    }
    case "CitationChangeGroup": {
      const citationType = dataField.fieldContent.citationType;
      const confidential = dataField.fieldContent.confidential;

      return `${confidential ? "Confidential " : " "}${_.startCase(
        citationType,
      )} citation`;
    }
    case "CrowdsourcedFile": {
      const name = dataField.fieldContent.name;
      const hostName = dataField.fieldContent.hostName;

      return `${name} (${_.startCase(hostName)})`;
    }
    case "LandCoveringLevel": {
      const verticalLevel = dataField.fieldContent.verticalLevel;
      return `Floor Level ${verticalLevel}`;
    }
    case "FloorArea": {
      const useTypes = dataField.fieldContent.useTypes?.join(", ");
      const name = dataField.fieldContent.name;
      const displayableName = name === "Floor Area" ? null : name;
      const standardizedArea = dataField.fieldContent?.standardizedArea;
      const useBasedArea = dataField.fieldContent?.useBasedArea;
      const unit = dataField.fieldContent?.unit;
      const useBasedString =
        !_.isObject(useBasedArea) && !_.isNil(useBasedArea)
          ? `${currencyAmount(useBasedArea, 0)} ${unit}`
          : null;
      const standardizedString =
        !_.isObject(standardizedArea) && !_.isNil(standardizedArea)
          ? `${currencyAmount(standardizedArea, 0)} SF`
          : null;
      const totalString = _.compact([
        useTypes,
        useBasedString,
        standardizedString,
      ]).join("; ");

      if (displayableName) {
        return `${displayableName} (${totalString})`;
      } else {
        return totalString || name;
      }
    }
    case "FloorAreaDownwardVerticalOpening": {
      const levelIds = dataField.fieldContent.landCoveringLevelIds;

      return `${pluralize("level", levelIds.length, true)} vertically open`;
    }
    case "PropertyDiagramVisualOrdering": {
      const direction = _.startCase(dataField.fieldContent?.direction);
      const order = dataField.fieldContent?.order;
      const adjustedOrder = _.isNumber(order)
        ? _.toNumber(order) + 1
        : "unknown";

      return `${direction} order: ${adjustedOrder}`;
    }
    case "ContentLocation": {
      const state = _.get(dataField, "fieldContent.state");
      const abbreviatedState = stateAbbreviationsUS[state];

      return `${_.get(dataField, "fieldContent.city")}, ${
        abbreviatedState || state
      }`;
    }
    case "GeographyRegion": {
      if (_.isObject(dataField.fieldContent.name)) {
        return "Target market boundaries";
      } else {
        return dataField.fieldContent?.name || "Target market boundaries";
      }
    }
    case "ContentPolygon": {
      const polygon = L.polygon(dataField.fieldContent.coordinates);
      const latLngs = polygon.getLatLngs()[0];
      const a = L.GeometryUtil.geodesicArea(latLngs);

      const area = a / 4047;
      return `${_.get(
        dataField,
        "decoratingContentSubType",
      )} boundaries: ${currencyAmount(area, 1)} acres`;
    }
    case "HuntSpaceRequirement": {
      const propertyUseField = dataField.fieldContent.propertyUse;

      return (
        propertyUseField?.fieldContent?.name || "Occupier Space Requirement"
      );
    }
    case "SpaceRequirementPhase": {
      const phaseNumber = dataField.fieldContent.order;

      if (phaseNumber === 0) {
        return "Initial Phase";
      } else {
        return `Phase ${phaseNumber + 1}`;
      }
    }
    default:
      return `Self-label needed: ${fieldName}`;
  }
}
